<template>
  <div>
    <vx-card>

      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">

        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ products.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : products.length }} de {{ products.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="products"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </vx-card>
  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue"
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import moduleDataList from '@/store/data-list/moduleDataList.js'
import moduleDataListCategories from '@/store/data-list-categories/moduleDataListCategories.js'

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      modules: AllCommunityModules,

      searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Id',
          field: 'id',
          width: 125,
          filter: true,
        //   checkboxSelection: true,
        //   headerCheckboxSelectionFilteredOnly: true,
        //   headerCheckboxSelection: true,
        },
        {
          headerName: 'Producto',
          field: 'name',
          filter: true,
          floatingFilter: true,
          width: 250,
        },
        {
          headerName: 'Categoría',
          field: 'category',
          filter: true,
          floatingFilter: true,
          width: 200,
        //   pinned: 'left'
        },
        {
          headerName: 'Precio',
          field: 'price',
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.price, '$ '),
        //   width: 100,
        },
        {
          headerName: 'Costo',
          field: 'cost',
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.cost, '$ '),
        //   width: 100,
        },
        {
          headerName: 'Stock',
          field: 'stock',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Stock Min.',
          field: 'stock_min',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Total Vendido',
          field: 'total_sales',
          filter: true,
        //   width: 100,
        },
        {
          headerName: 'Estado',
          field: 'status_label',
          filter: true,
          floatingFilter: true,
          width: 125,
          cellStyle: params => params.data.status == 1 ? {color: 'green'} : {color: 'red'},
        },
      ],
      products: [],
    }
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if(val <= 576) {
        this.maxPageNumbers = 4
        // this.gridOptions.columnApi.setColumnPinned('email', null)
      }
    //   else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    }
  },
  computed: {
    paginationPageSize() {
      if(this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 50
    },
    totalPages() {
      if(this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {

    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    loadProducts() {
        this.$store.dispatch("dataList/getProducts", this.$store.state.AppActiveUser.institution.id)
        
        .then(response => {
            let products = response.data.data
            let categories = this.$store.getters['dataListCategories/getCategories']
            if (products && categories)
                products.forEach(element => {
                    element.category = categories.find((c) => c.id == element.id_category).name
                    element.status_label = (element.status == 1) ? 'Activo' : 'Inactivo'
                });
            this.products = products
        })
        .catch(error => {console.log(error)})
    }
  },
  created() {
    if(!moduleDataList.isRegistered) {
        this.$store.registerModule('dataList', moduleDataList)
        moduleDataList.isRegistered = true
    }
    if(!moduleDataListCategories.isRegistered) {
        this.$store.registerModule('dataListCategories', moduleDataListCategories)
        moduleDataListCategories.isRegistered = true
    }
    
    if (this.$store.getters['dataListCategories/getCategories'].length == 0) {
        this.$store.dispatch('dataListCategories/getCategories', null)
        .then(() => {
            this.loadProducts()
        })
        .catch(error => {console.log(error)})
    } else {
        this.loadProducts()
    }
    
  },
  mounted() {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
  }
}

</script>
